import React from "react"

const Editor = () => {
  return (
    <div>
      hello
    </div>
  )
};

export default Editor
