import React from "react"

const Compiler = () => {
  return (
    <div>
      compiler
    </div>
  )
};

export default Compiler
