import React from "react"

const LearnerStats = () => {
  return (
    <div>
      hello
    </div>
  )
};

export default LearnerStats
