import React from "react"

const SkilledStats = () => {
  return (
    <div>
      hello world
    </div>
  )
};

export default SkilledStats
