import React from "react"
// import Navbar from "../Navbar";
// import HomeAviral from "../learner/HomeAviral";

import ProblemofDay from "../Problem";

const Aviral = () => {
  return (
    <div>
      {/* <Navbar/> */}
      {/* <HomeAviral/> */}
      {/* <SignUpAviral/> */}
   <ProblemofDay/>
    </div>
  )
};

export default Aviral
